import React from "react";
import Paragraph from "../../components/paragraph";
import SEO from "../../components/seo";
import WorkDetail from "../../components/work-detail";
import WorkDetailImage from "../../components/work-detail-image";
import WorkDetailIntro from "../../components/work-detail-intro";
import WorkDetailText from "../../components/work-detail-text";

import introImageSm from "../../images/work/report/intro-sm.jpg";
import introImageMd from "../../images/work/report/intro-md.jpg";
import introImageLg from "../../images/work/report/intro-lg.jpg";
import contentImage1Sm from "../../images/work/report/content-1-sm.jpg";
import contentImage1Md from "../../images/work/report/content-1-md.jpg";
import contentImage1Lg from "../../images/work/report/content-1-lg.jpg";
import contentImage2Sm from "../../images/work/report/content-2-sm.jpg";
import contentImage2Md from "../../images/work/report/content-2-md.jpg";
import contentImage2Lg from "../../images/work/report/content-2-lg.jpg";
import contentImage3Sm from "../../images/work/report/content-3-sm.jpg";
import contentImage3Md from "../../images/work/report/content-3-md.jpg";
import contentImage3Lg from "../../images/work/report/content-3-lg.jpg";
import contentImage4Sm from "../../images/work/report/content-4-sm.jpg";
import contentImage4Md from "../../images/work/report/content-4-md.jpg";
import contentImage4Lg from "../../images/work/report/content-4-lg.jpg";
import contentImage5Sm from "../../images/work/report/content-5-sm.jpg";
import contentImage5Md from "../../images/work/report/content-5-md.jpg";
import contentImage5Lg from "../../images/work/report/content-5-lg.jpg";
import contentImage6Sm from "../../images/work/report/content-6-sm.jpg";
import contentImage6Md from "../../images/work/report/content-6-md.jpg";
import contentImage6Lg from "../../images/work/report/content-6-lg.jpg";

const ReportPage = () => (
  <>
    <SEO title="DISH Cares Annual Report" />
    <WorkDetail title="DISH Cares Annual Report">
      <WorkDetailIntro
        title="Showing a TV Provider’s Commitment to Making a Positive Impact"
        description={
          <Paragraph variant="work-detail">
            DISH Cares, the corporate citizenship program of DISH Network, puts
            together a yearly report to highlight the impact of their employee
            volunteering events, community engagement, and sustainable business
            practices. This booklet was displayed at DISH’s annual trade show to
            create awareness and encourage authorized retailers to become
            involved in their own communities.
          </Paragraph>
        }
        images={{
          sm: introImageSm,
          md: introImageMd,
          lg: introImageLg,
          alt: "Three stacked annual report booklets.",
        }}
        role="Design"
        client="DISH Network"
        project="report"
      />
      <WorkDetailText heading="A Connectivity Company">
        <Paragraph variant="work-detail">
          The DISH Cares marketing team approached our in-house design agency to
          design this booklet revolving around the theme of “connectivity”. The
          result is a design that hints at interpersonal connections and DISH’s
          company vision of technology changing the way the world communicates.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage1Sm,
          md: contentImage1Md,
          lg: contentImage1Lg,
          alt: "Intro page.",
        }}
      />
      <WorkDetailText heading="Creating a Unified Design">
        <Paragraph variant="work-detail">
          The biggest challenge was keeping the overarching DISH brand in mind
          while incorporating DISH Cares, which has its own colors and icon
          style used on flyers, T-shirts, and other marketing material.
        </Paragraph>
        <Paragraph variant="work-detail">
          The introduction uses the DISH Network brand colors, and the three
          subsequent sections use DISH Cares colors and icons that distinguish
          the three sections of the report.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage2Sm,
          md: contentImage2Md,
          lg: contentImage2Lg,
          alt: "DISH Cares introduction page.",
        }}
      />
      <WorkDetailImage
        images={{
          sm: contentImage3Sm,
          md: contentImage3Md,
          lg: contentImage3Lg,
          alt: "Three section intro page spreads.",
        }}
        caption="Each section intro with their designated color"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          Consistency in how I treated typography, design elements, and icons
          helped the three distinctly colored sections to still feel like they
          all belonged in the same report.
        </Paragraph>
        <Paragraph variant="work-detail">
          By using bold colors, highlighting important statistics, and
          incorporating photography throughout, the report provides a lot of
          information while being easy to scan in a trade show setting.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage4Sm,
          md: contentImage4Md,
          lg: contentImage4Lg,
          alt: "Page about diversity and inclusion.",
        }}
      />
      <WorkDetailImage
        images={{
          sm: contentImage5Sm,
          md: contentImage5Md,
          lg: contentImage5Lg,
          alt: "Statistics infographic for Our Communities section.",
        }}
      />
      <WorkDetailImage
        images={{
          sm: contentImage6Sm,
          md: contentImage6Md,
          lg: contentImage6Lg,
          alt: "Statistics infographic for Our Planet section.",
        }}
      />
    </WorkDetail>
  </>
);

export default ReportPage;
